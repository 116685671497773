import React, { useState } from "react";
import LtModal from "./component/LtModal";

import ScrollAnimation from "react-animate-on-scroll";
import { useSelector } from "react-redux";
import Image from "../../../components/Image";

const CoachList = () => {
  // const sliderRef = useRef(null);
  const [show, setShow] = useState(false); // State for modal visibility
  const [selectedCoach, setSelectedCoach] = useState(null); // State for selected coach

  const frontend = useSelector(state => state?.frontend?.customizationData);
  const coachSection = frontend?.customize_frontend?.meet_our_coaches || "";
  const coaches = frontend?.coaches || {};
  // console.log(selectedCoach);

  const handleClose = () => setShow(false);
  const handleShow = coach => {
    setSelectedCoach(coach);
    setShow(true);
  };

  return (
    <div className="overflow-hidden bg-[#F4F9FF] py-[70px] pb-[100px]" id="coaches">
      <div className="container mx-auto px-4">
        <div className="mb-[50px] text-center">
          <ScrollAnimation animateIn="fadeInDown">
            <h6 className="text-theme">Our Coaches</h6>
            <h2 className="headingFont font-bold">Expert Guidance for Every Player</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInDown" delay={500}>
            <p className="text-justify md:text-center" dangerouslySetInnerHTML={{ __html: coachSection }} />
          </ScrollAnimation>
        </div>

        <div className="mb-[50px] flex flex-wrap justify-center gap-8">
          {coaches.length > 0 &&
            coaches.map(
              (coach, index) =>
                coach.image_name && (
                  <div key={index} className="flex w-full flex-col items-center md:w-1/3 lg:w-1/4">
                    <div className="program_card w-100 cursor-pointer px-3" onClick={() => handleShow(coach)}>
                      <Image src={`${coach.image_path}/${coach.image_name}`} className="w-full rounded object-cover shadow-sm" />
                    </div>

                    <div className="p-4 text-center">
                      <h6 className="mb-1 mt-3 font-bold text-[#445BEF]">
                        {coach.first_name_en} {coach.last_name_en}
                      </h6>
                      <p className="text-sm">{coach?.center_coaches?.length > 0 && coach?.center_coaches[0]?.role?.name}</p>
                    </div>
                  </div>
                )
            )}
        </div>

        {/* Modal for Coach Details */}
        <LtModal isOpen={show} onClose={handleClose} heading="" width={500}>
          <div className="flex justify-center">
            <img
              src={`${selectedCoach?.image_path}/${selectedCoach?.image_name}`}
              alt={selectedCoach?.first_name_en}
              className="mx-auto mb-3 h-[150px] w-[150px] rounded-lg object-cover text-center"
            />
          </div>
          <h4 className="fw-bold my-3 text-center">
            {selectedCoach?.first_name_en} {selectedCoach?.last_name_en}
          </h4>
          {selectedCoach?.coach_experience ? <p>{selectedCoach?.coach_experience?.experience}</p> : null}
          <p></p>
        </LtModal>
      </div>
    </div>
  );
};

export default CoachList;
